<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <div class="text-center text-h4 pa-5">Project Details</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="3" class="text-center text-lg-start">
                                <span class="font-weight-bold">Project Name: </span>
                                <span>{{projectDetails.project.name}}</span>
                            </v-col>
                            <v-col cols="12" lg="3" class="text-center text-lg-start">
                                <span class="font-weight-bold">Start Date: </span>
                                <span>{{projectDetails.project.start_date}}</span>
                            </v-col>
                            <v-col cols="12" class="text-center text-lg-start">
                                <span class="font-weight-bold">Project Discription: </span>
                                <span>{{projectDetails.project.description}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="4" class="text-center text-lg-start">
                                <span class="font-weight-bold">Project Ref. Person: </span>
                                <span>{{projectDetails.project.ref_person}}</span>
                            </v-col>
                            <v-col cols="12" lg="4" class="text-center text-lg-start">
                                <span class="font-weight-bold">Ref. Person Contect: </span>
                                <span>{{projectDetails.project.contact_number}}</span>
                            </v-col>
                            <v-col cols="12" lg="6" class="text-center text-lg-start">
                                <span class="font-weight-bold">Ref. Person Address: </span>
                                <span>{{projectDetails.project.address}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <span class="text-h6">Project Investment Stats</span>
                            </v-col>
                            <v-col cols="12" lg="3" class="text-center">
                                <span class="font-weight-bold">Total Investments</span><br>
                                <span>{{projectDetails.total_investment_count}}</span>
                            </v-col>
                            <v-col cols="12" lg="3" class="text-center">
                                <span class="font-weight-bold red--text">Total Investment Amount</span><br>
                                <span class="red--text">{{projectDetails.total_investment_amount}}</span>
                            </v-col>
                            <v-col cols="12" lg="3" class="text-center">
                                <span class="font-weight-bold">Total Investment Return</span><br>
                                <span>{{projectDetails.total_roi_count}}</span>
                            </v-col>
                            <v-col cols="12" lg="3" class="text-center">
                                <span class="font-weight-bold green--text">Total Investment Return Amount</span><br>
                                <span class="green--text">{{projectDetails.total_roi_amount}}</span>
                            </v-col>
                        </v-row>
                        <v-row class="justify-center">
                            <v-col cols="12" class="text-center">
                                <span class="text-h6">Project Transactions Report</span>
                            </v-col>
                            <v-col cols="12" lg="3">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="start_date"
                                        label="Start date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="start_date"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="end_date"
                                        label="End date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="end_date"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" lg='2' class="text-center text-lg-left mt-3">
                                <v-btn color="primary" class="px-10" @click="getFilteredTrasactions()">
                                Filter
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-data-table :headers="fields" :items="projectTransections" hide-default-footer mobile-breakpoint="0" :loading="transitionLoading" loading-text="Loading Data ...">
                                    <template v-slot:[`item.amount`]="{ item }"><span :class="`${item.is_return=== 1?'green':'red'}--text`">{{item.amount}}</span></template>
                                    <template v-slot:[`item.description`]="{ item }"><span>{{item.description===null?'N/A':item.description}}</span></template>
                                    <template v-slot:[`item.currency`]="{ item }"><span>{{item.currency===null?'N/A':item.currency.toUpperCase()}}</span></template>
                                </v-data-table>
                            </v-col>
                            <v-col class="test-center mb-5">
                                <v-pagination v-model="current_page" :length="total_pages" @next="currentPage()" @previous="currentPage()" @input="currentPage()"></v-pagination>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-overlay :value="loading" color="white">
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                    color="primary"
                                ></v-progress-circular>
                                </v-overlay>
                            </v-col>
                        </v-row>
                    </v-container>   
                </v-card>
            </v-col>
        </v-row>
  </v-container>
</template>
<script>
import {get_project_details,get_projact_transactions} from "@api/project"
export default {
    data() {
        return {
            fields: [
                {
                    text:"Date",
                    value:"date",
                },
                {
                    text:"Description",
                    value:"description",
                },
                { 
                    text:"Amount",
                    value:"amount",
                },
                { 
                    text:"Currency",
                    value:"currency",
                },
            ],
            start_date:'',
            end_date:'',
            projectDetails:{
                project:{

                }
            },
            projectTransections:[],
            current_page:1,
            total_pages:1,
            loading: false,
            errResp:'',
            transitionLoading:false,
        }
    },
    mounted() {
        this.getProjectDetails({id:this.$route.params.id})
        this.getProjectDetailsTransactions({id:this.$route.params.id})
    },
    methods: {
        getProjectDetails(data){
            this.loading= true;
            this.errResp= '';
            get_project_details(data)
            .then(resp=>{
                this.loading= false;
                if(resp.status == 'Success'){
                    this.projectDetails= resp.data;
                    
                }else{
                    this.errResp= resp.errors;
                }
            })
        },
        getProjectDetailsTransactions(data){
            this.transitionLoading= true;
            get_projact_transactions(data)
            .then(resp=>{
                this.transitionLoading= false;
                if(resp.status == 'Success'){
                    this.projectTransections= resp.data.data;
                    this.current_page= resp.data.current_page;
                    this.total_pages= resp.data.last_page
                }else{
                    this.errResp= resp.errors;
                }
            })
        },
        getFilteredTrasactions(){
            let data={
                start_date:this.start_date,
                end_date:this.end_date
            }
            this.getProjectDetailsTransactions({id:this.$route.params.id,data})
        },
        currentPage() {
            let data={
                page:Number(this.current_page)
            }
            this.getProjectDetailsTransactions({id:this.$route.params.id,data})
        },
    }
}
</script>

<style>

</style>